import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';

class List extends Component {

    data = this.props.Data;
    language = this.props.Language;

    addItemToCart = () => {
        this.props.addCartItem(this.data)
    }

    render() {
        return (
            <div className="style-list">
            <div className="side-left">
                <div className='style-list-info-footer'>
                    <div>

                        <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                            {/* {this.data.order??0} -  */}
                            {
                                this.props.Language == "en" ? this.data.name_eng
                                : this.props.Language == "krd" ? this.data.name_krd
                                : this.props.Language == "tr" ? this.data.name_tr
                                : this.data.name_ar
                            } 
                        </h5>
                        <p> 
                            {
                                this.props.Language == "en" ? this.data.desc_eng
                                : this.props.Language == "krd" ? this.data.desc_krd
                                : this.props.Language == "tr" ? this.data.desc_tr
                                : this.data.desc_ar
                            } 
                        </p>
                        <div className='price'> 
                            {
                                (Array.isArray(this.data.prices) && this.data.prices.length)? 
                                    this.data.prices.map(
                                        (item, index ) => 
                                            <span key={index}> 
                                                {
                                                    this.props.Language == "en" ? item.name_en
                                                    : this.props.Language == "krd" ? item.name_krd
                                                    : this.props.Language == "tr" ? item.name_tr
                                                    : item.name_ar
                                                }
                                                <span className='mx-2'>{ item.price } {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span>
                                            </span> )
                                :
                                <span> {this.data.price} {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'} </span> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            
                <PhotoView src={this.data.image} overlay={this.data} >
                    <div className="side-right">
                        <Imgloading Data={this.data} />
                    </div>
                </PhotoView>
            
        </div>
        );
    }
}

export default List;