import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class MenuItem extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
                    <div className={`head-menu-item ${ (this.props.IsActive)? 'head-menu-item-active': ''}`} onClick={ () => this.props.handleMenuClick(this.props.Index)} >
                        {
                            this.props.Language == "en" ? this.data.name_eng
                            : this.props.Language == "krd" ? this.data.name_krd
                            : this.props.Language == "tr" ? this.data.name_tr
                            : this.data.name_ar
                        }
                    </div>
                );
    }
}

export default MenuItem;